import React, {useEffect, useState} from 'react';
import {useAuth} from "../hooks/useAuth";
import {Navigate} from "react-router-dom";
import Layout from "../components/account/Layout";
import AccountStatus from "../components/Statistics/AccountStatus";
import Alerts from "../components/Statistics/Alerts";
import PinnedProjects from "../components/Statistics/PinnedProjects";
import AllProjects from "../components/Statistics/AllProjects";
import brandLogo from '../assets/Group 12.svg';
import Nav from "../components/Routing/Nav";
import userServices from "../services/user-services";

const Home = () => {
        const [monitors, setMonitors] = useState([]);
        const [data, setData] = useState({});
        const {user} = useAuth();

        useEffect(() => {
                userServices.getMonitorData().then(async res => {
                    await setData(res.data)
                    await setMonitors(res.data.monitors)
                })
            }, []
        );
        useEffect(() => {
            setTimeout(() => {
                userServices.getMonitorData().then(async res => {
                    await setData(res.data)
                    await setMonitors(res.data.monitors)
                })
            }, 1000 * 60)
        })

        if (!user) {
            return <Navigate to="/login"/>;
        }


        return (
            <Layout>
                <Nav data={data}/>
                <div className="flex flex-col w-full px-10 py-20 md:pt-16 my-auto">
                    {window.innerHeight <= 2000 ?
                        <div className=" flex flex-col md:grid md:grid-cols-3 2xl:flex pb-10 w-full gap-10 ">
                            <AccountStatus monitors={monitors} data={data}/>
                            <Alerts monitors={monitors} setMonitors={setMonitors} data={data}/>
                            <PinnedProjects monitors={monitors}/>
                        </div> : <div className=" flex flex-col md:grid md:grid-cols-3 2xl:flex pb-10 w-full gap-10">
                            <div className="!w-1/2 mx-auto">
                                <AccountStatus monitors={monitors}/>
                            </div>
                            <div className="grid grid-cols-2 gap-10">
                                <Alerts monitors={monitors} setMonitors={setMonitors}/>
                                <PinnedProjects monitors={monitors}/></div>
                        </div>}
                    <div>
                        <AllProjects monitors={monitors} setMonitors={setMonitors}/>
                        <div className="absolute bottom-5 text-gray">User: {data.displayTitle}</div>
                        <div className="absolute bottom-5 right-10 text-xl flex flex-col items-center z-50">
                            <img src={brandLogo} alt=""/>
                        </div>
                    </div>
                </div>


            </Layout>

        )
    }
;

export default Home;
