import {Card} from "../Card";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper";
import logo from '../../assets/Group 8.svg'

export default function Alerts({monitors, setMonitors, data}) {

    const getAlerts = () => {
        let alertArray = monitors?.filter(x => x.isHealthy === false).map(alert => <div key={alert.id}
                                                                                        className='flex flex-row gap-x-5 lg:flex-col xl:flex-row items-center justify-between w-full py-3 px-2 border-y border-[#272727]'>
            <div className="flex flex-col text-xl text-left">
                <span className='pb-4'>
{alert.displayName}
                </span>
                <div className="flex text-gray flex-col gap-5 lg:flex-row text-sm">
                    <div>
                        Status: <span className='text-brandAlert font-bold'>OFFLINE</span>
                    </div>
                    <div className='text-sm'>
                        Domain: {alert.domainName}
                    </div>
                </div>
                <div className="text-gray text-sm">
                    Triggered: {new Date(alert.lastCheckedDateTimeUtc).toLocaleDateString('hu-HU') + ' ' + new Date(alert.lastCheckedDateTimeUtc).getHours() + ":" + new Date(alert.lastCheckedDateTimeUtc).getMinutes()}
                </div>
            </div>
            <div
                className="py-2  bg-brandAlert px-4 flex justify-center rounded-lg text-xs  uppercase  font-bold cursor-pointer"
                onClick={() => setMonitors(prevState =>
                    prevState.map(el => el.id === alert.id ? {
                        ...el,
                        isHealthy: !alert.isHealthy
                    } : el)
                )}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                </svg>

            </div>
        </div>);

        const n = 3

        let newArray = alertArray.reduce((r, e, i) =>
                (i % n ? r[r.length - 1].push(e) : r.push([e])) && r
            , []);

        if (newArray.length === 0) {
            return <div className='uppercase flex flex-col w-full items-center'><span className='pb-3 font-bold'>Perfect you can have a
                rest... </span>
                <img src={logo} alt="Resting monk"/>

            </div>
        } else {
            return <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} slidesPerView={1}
                           pagination={true}
                           autoplay={
                               {delay: 15 * 1000}
                           } className='!m-0 max-w-full'>
                {newArray.map(x => <SwiperSlide key={x + Math.random()}
                                                className='flex flex-col'>{x}</SwiperSlide>)}
            </Swiper>
        }

    }

    return (<>
        <Card>
            <div
                className="flex flex-col gap-2 items-center xl:flex-row justify-between uppercase mb-5 text-lg text-gray">
                <div className="flex items-center justify-center gap-2 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-6 w-6 fill-gray'>
                        <path
                            d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"/>
                    </svg>

                    Alerts
                </div>
                Last
                Checked: {new Date(data.lastCheckedDateTimeUtc).toLocaleDateString('hu-HU') + ' ' + new Date(data.lastCheckedDateTimeUtc).getHours() + ":" + new Date(data.lastCheckedDateTimeUtc).getMinutes()}
            </div>
            <div className="flex relative w-full flex-col text-center mx-auto ">

                {getAlerts()}


            </div>


        </Card>
    </>);
};
