import React from 'react';
import {Card} from "../components/Card";
import Layout from "../components/account/Layout";
import {useAuth} from "../hooks/useAuth";

const Login = () => {
    const {login} = useAuth();
    const submit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        login({
            email: data.get("email"),
            password: data.get("password")
        });
    }

    return (
        <Layout>
            <div className="flex w-full items-center justify-center">
                <Card>
                    <div className="uppercase py-6 text-5xl font-bold md:min-w-[600px]">
                        <h4 className="card-header">Login</h4>
                    </div>

                    <div className="card-body">
                        <form onSubmit={submit}>
                            <div className="flex flex-col">
                                <fieldset className='border-white border-2 p-2 mb-4 rounded-md'>
                                    <legend className=' uppercase italic text-sm font-bold px-2'>Username</legend>
                                    <input name="username" type="text"
                                           className='w-full bg-transparent px-4'/>
                                </fieldset>

                            </div>
                            <div className="form-group mb-10">
                                <fieldset className='border-white border-2 p-2 mb-4 rounded-md'>
                                    <legend className=' uppercase italic font-bold text-sm px-2'>Password</legend>
                                    <input name="password" type="password"
                                           className='form-control bg-transparent w-full px-4'/>
                                </fieldset>

                            </div>
                            <div className="flex items-center">
                                <button
                                    className="px-10 py-1 bg-transparent rounded-md text-2xl font-bold text-gray-600 border-2 hover:bg-white hover:text-black hover:border-2 hover:border-white">
                                    Login
                                </button>
                            </div>

                        </form>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default Login;