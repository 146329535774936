import {createContext, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "./useLocalStorage";

const UseAuth = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    const login = async (data) => {
        setUser(data);
        navigate("/", {replace: true});
    };

    const logout = () => {
        setUser(null);
        navigate("/login", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        [user]
    );

    return <UseAuth.Provider value={value}>{children}</UseAuth.Provider>;
};

export const useAuth = () => {
    return useContext(UseAuth);
};
