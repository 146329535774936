import axios from "axios"


const API_URL = "https://cdn.monitormonk.com/status.json";

const getMonitorData = () => {
    return axios.get(API_URL)
}

const userServices = {
    getMonitorData
}

export default userServices;
