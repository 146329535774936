import {Card} from "../Card";
import 'chart.js/auto';
import {Doughnut} from 'react-chartjs-2';

import status from '../../data/test.json';


export default function AccountStatus({monitors, data}) {

    const dataA = {
        labels: ['Online', 'Offline',], datasets: [{
            data: [monitors.filter(monitor => monitor?.isHealthy === true).length, monitors.filter(monitor => monitor.isHealthy === false).length],
            backgroundColor: ['#44EAAE', '#FB7021'],
            cutout: '120',
            borderWidth: ['0', '5'],
            borderColor: ['#44EAAE', '#FB7021'],
            weight: 0.2
        }],
    };
    const dataB = {
        labels: ['Online', 'Offline',], datasets: [{
            data: [monitors.filter(monitor => monitor?.isHealthy === true).length, monitors.filter(monitor => monitor.isHealthy === false).length],
            backgroundColor: ['#44EAAE', '#FB7021'],
            cutout: '300',
            borderWidth: ['0', '5'],
            borderColor: ['#44EAAE', '#FB7021'],
            weight: 0.2
        }],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    return (<>
        <Card>
            <div
                className="flex flex-col gap-2 items-center xl:flex-row justify-between uppercase mb-5 text-lg text-gray">
                <div className="flex items-center justify-center gap-2 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='h-6 w-6 fill-gray'>
                        <path
                            d="M304 16.58C304 7.555 310.1 0 320 0C443.7 0 544 100.3 544 224C544 233 536.4 240 527.4 240H304V16.58zM32 272C32 150.7 122.1 50.34 238.1 34.25C248.2 32.99 256 40.36 256 49.61V288L412.5 444.5C419.2 451.2 418.7 462.2 411 467.7C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zM558.4 288C567.6 288 575 295.8 573.8 305C566.1 360.9 539.1 410.6 499.9 447.3C493.9 452.1 484.5 452.5 478.7 446.7L320 288H558.4z"/>
                    </svg>
                    Overview
                </div>
                last
                checked: {new Date(data.lastCheckedDateTimeUtc).getHours() + ":" + new Date(data.lastCheckedDateTimeUtc).getMinutes()}
            </div>

            <div className="flex relative flex-col text-center mx-auto">
                {window.innerHeight <= 2000 ? <div className="flex relative flex-col text-center mx-auto">
                    <Doughnut
                        data={dataA}
                        options={options}
                    />
                    <div
                        className="absolute top-1/2 left-1/2 text-[90px] font-bold -translate-x-1/2 -translate-y-1/2 after:content-['%'] after:text-[30px] after:absolute after:-right-7">{(monitors.filter(monitor => monitor.isHealthy === true).length / monitors.length).toFixed(2) * 100}
                    </div>
                </div> : <div className="flex relative flex-col text-center mx-auto w-[750px]">
                    <Doughnut
                        data={dataB}
                        options={options}
                    />
                    <div
                        className="absolute top-1/2 left-1/2 text-[90px] font-bold -translate-x-1/2 -translate-y-1/2 after:content-['%'] after:text-[30px] after:absolute after:-right-7">{(monitors.filter(monitor => monitor.isHealthy === true).length / monitors.length).toFixed(2) * 100}
                    </div>
                </div>}
                <div
                    className="absolute top-1/2 left-1/2 text-[90px] font-bold -translate-x-1/2 -translate-y-1/2 after:content-['%'] after:text-[30px] after:absolute after:-right-7">{(monitors.filter(monitor => monitor.isHealthy === true).length / monitors.length).toFixed(2) * 100}
                </div>
            </div>
            <div
                className="uppercase my-5 text-center font-bold text-2xl">{(monitors?.filter(monitor => monitor.isHealthy === true).length / monitors.length).toFixed(2) * 100 > 60 ?
                <span className='text-brandSuccess'>healthy</span> :
                <span className='text-brandAlert'>critical</span>}</div>
            <div
                className="text-center justify-around flex flex-col xl:flex-row items-center w-full justify-center text-gray uppercase">
                <div
                    className="before:content-[''] before:rounded-full before:bg-brandSuccess before:w-[18px] before:h-[18px] before:absolute relative before:-translate-x-[30px] before:translate-y-[3px]"> online
                    ({monitors.filter(monitor => monitor.isHealthy === true).length})
                </div>
                <div
                    className="before:content-[''] before:rounded-full before:bg-brandAlert before:w-[18px] before:h-[18px] before:absolute relative before:-translate-x-[30px] before:translate-y-[3px]"> offline
                    ({monitors.filter(monitor => monitor.isHealthy === false).length})
                </div>
            </div>

        </Card>
    </>);
};
