import {Card} from "../Card";
import {useAuth} from "../../hooks/useAuth";


const Nav = ({data}) => {
    const {logout} = useAuth();

    return (
        <nav
            className="fixed w-full md:px-10 top-0  z-20 flex items-center justify-center">
            <Card classes='w-full !py-0 md:!py-0 md:!px-3 flex justify-center'>
                <div onClick={logout}
                     className="px-12 py-1 cursor-pointer nav-link flex justify-center font-bold rounded-b-lg border-white  text-md md:text-lg lg:text-2xl uppercase flex items-center gap-5">
                    <img src={data.displayIconUrl} alt="" className='h-16'/>
                    {data.displayTitle}
                </div>

            </Card>
        </nav>
    );
}

export default Nav;
