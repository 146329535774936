import styles from './Card.module.scss'


export function Card(props) {
    return (
        <div
            className={styles.card + " flex flex-col " + props.classes}>
            {props.children}
        </div>
    );
}