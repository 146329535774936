import {Card} from "../Card";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

export default function PinnedProjects({monitors}) {

    const getPinned = () => {
        let pinnedArray = monitors.filter(x => x.isPinned === true).map(pinned => <div key={pinned.id}
                                                                                       className='flex flex-row gap-5 lg:flex-col xl:flex-row items-center justify-between w-full py-3 px-2 border-y border-[#272727]'>
            <div className="flex flex-col text-xl text-left">
                <span className='pb-4'>
{pinned.displayName}
                </span>
                <div className="flex text-gray flex-col gap-5 lg:flex-row text-sm">
                    <div>
                        Status: {pinned.isHealthy ? <span className='text-brandSuccess font-bold'>ONLINE</span> :
                        <span className='text-brandAlert font-bold'>OFFLINE</span>}
                    </div>
                    <div className='text-sm'>
                        Domain: {pinned.domainName}
                    </div>
                </div>
                <div className="text-gray text-sm">
                    Checked: {new Date(pinned.lastCheckedDateTimeUtc).toLocaleDateString('hu-HU') + ' ' + new Date(pinned.lastCheckedDateTimeUtc).getHours() + ":" + new Date(pinned.lastCheckedDateTimeUtc).getMinutes()}
                </div>
            </div>
            {pinned.isHealthy ?
                <div className="px-4 py-2 bg-brandSuccess font-bold rounded-lg uppercase">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"/>
                    </svg>
                </div> :
                <div className="px-4 py-2 bg-stone-600 font-bold rounded-lg uppercase">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"/>
                    </svg>
                </div>}

        </div>);

        const n = 3

        let newArray = pinnedArray.reduce((r, e, i) =>
                (i % n ? r[r.length - 1].push(e) : r.push([e])) && r
            , []);


        return newArray.map(x => <SwiperSlide key={x + Math.random()}
                                              className=' flex flex-col'>{x}</SwiperSlide>)
    }

    return (<>
        <Card>
            <div
                className="flex flex-col gap-2 items-center xl:flex-row justify-between uppercase mb-5 text-lg text-gray">
                <div className="flex items-center justify-center gap-2 text-white">

                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 384 512" className='w-6 h-6 fill-gray'>
                        <path
                            d="M32 32C32 14.33 46.33 0 64 0H320C337.7 0 352 14.33 352 32C352 49.67 337.7 64 320 64H290.5L301.9 212.2C338.6 232.1 367.5 265.4 381.4 306.9L382.4 309.9C385.6 319.6 383.1 330.4 377.1 338.7C371.9 347.1 362.3 352 352 352H32C21.71 352 12.05 347.1 6.04 338.7C.0259 330.4-1.611 319.6 1.642 309.9L2.644 306.9C16.47 265.4 45.42 232.1 82.14 212.2L93.54 64H64C46.33 64 32 49.67 32 32zM224 384V480C224 497.7 209.7 512 192 512C174.3 512 160 497.7 160 480V384H224z"/>
                    </svg>


                    Pinned monitors
                </div>
            </div>
            <div className="flex relative w-full flex-col text-center mx-auto ">
                <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} slidesPerView={1}
                        pagination={true}
                        autoplay={
                            {delay: 12 * 1000}
                        } className='!m-0 max-w-full'>
                    {getPinned()}
                </Swiper>

            </div>


        </Card>
    </>);
};
