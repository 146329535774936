import {Card} from "../Card";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

export default function AllProjects({monitors, setMonitors}) {
    const getProjects = () => {
        let projectArray = monitors.map(project =>
            <div key={project.id}>
                <Card classes="!p-2 ">
                    <div
                        className='flex flex-row gap-5 lg:flex-col xl:flex-row items-center justify-between w-full px-4 py-2 relative'>
                        <div className="flex flex-col text-xl text-left">
                <span className='pb-4 flex items-center gap-3'> {project.isHealthy ?
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-brandSuccess" viewBox="0 0 20 20"
                         onClick={() => setMonitors(prevState =>
                             prevState.map(el => el.id === project.id ? {
                                 ...el,
                                 isHealthy: !project.isHealthy
                             } : el)
                         )}>
                        <path fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"/>
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-stone-600" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"/>
                    </svg>}
                    {project.displayName}
                </span>
                            <div className="flex text-gray flex-col gap-5 lg:flex-row text-sm">
                                <div>
                                    Status: {project.isHealthy ?
                                    <span className='text-brandSuccess font-bold'>ONLINE</span> :
                                    <span className='text-brandAlert font-bold'>OFFLINE</span>}
                                </div>
                                <div className='text-sm'>
                                    Domain: {project.domainName}
                                </div>
                            </div>
                            <div className="text-gray text-sm">
                                Triggered: {new Date(project.lastCheckedDateTimeUtc).toLocaleDateString('hu-HU') + ' ' + new Date(project.lastCheckedDateTimeUtc).getHours() + ":" + new Date(project.lastCheckedDateTimeUtc).getMinutes()}
                            </div>
                        </div>
                        {
                            !project.isPinned ?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                     className="h-6 w-6 absolute top-0 right-1 fill-gray stroke-[#ffffff25] hover:fill-yellow-600 cursor-pointer"
                                     onClick={() => setMonitors(prevState =>
                                         prevState.map(el => el.id === project.id ? {
                                             ...el,
                                             isPinned: !project.isPinned
                                         } : el)
                                     )}
                                >
                                    <path
                                        d="M32 32C32 14.33 46.33 0 64 0H320C337.7 0 352 14.33 352 32C352 49.67 337.7 64 320 64H290.5L301.9 212.2C338.6 232.1 367.5 265.4 381.4 306.9L382.4 309.9C385.6 319.6 383.1 330.4 377.1 338.7C371.9 347.1 362.3 352 352 352H32C21.71 352 12.05 347.1 6.04 338.7C.0259 330.4-1.611 319.6 1.642 309.9L2.644 306.9C16.47 265.4 45.42 232.1 82.14 212.2L93.54 64H64C46.33 64 32 49.67 32 32zM224 384V480C224 497.7 209.7 512 192 512C174.3 512 160 497.7 160 480V384H224z"/>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                     className="h-6 w-6 absolute top-0 right-1 fill-yellow-600 hover:fill-grey cursor-pointer"
                                     onClick={() => setMonitors(prevState =>
                                         prevState.map(el => el.id === project.id ? {
                                             ...el,
                                             isPinned: !project.isPinned
                                         } : el)
                                     )}>
                                    <path
                                        d="M32 32C32 14.33 46.33 0 64 0H320C337.7 0 352 14.33 352 32C352 49.67 337.7 64 320 64H290.5L301.9 212.2C338.6 232.1 367.5 265.4 381.4 306.9L382.4 309.9C385.6 319.6 383.1 330.4 377.1 338.7C371.9 347.1 362.3 352 352 352H32C21.71 352 12.05 347.1 6.04 338.7C.0259 330.4-1.611 319.6 1.642 309.9L2.644 306.9C16.47 265.4 45.42 232.1 82.14 212.2L93.54 64H64C46.33 64 32 49.67 32 32zM224 384V480C224 497.7 209.7 512 192 512C174.3 512 160 497.7 160 480V384H224z"/>
                                </svg>}

                    </div>
                </Card>
            </div>
        );

        const n = 6

        let newArray = projectArray.reduce((r, e, i) =>
                (i % n ? r[r.length - 1].push(e) : r.push([e])) && r
            , []);


        return newArray.map(x => <SwiperSlide key={x + Math.random()}
                                              className='grid md:grid-cols-3 !w-full gap-x-8 gap-y-8 px-2'>{x}</SwiperSlide>)
    }

    return (
        <div className='col-span-3'>


            <div className="flex relative w-full flex-col text-center">
                <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} slidesPerView={1}
                        pagination={true}
                        autoplay={
                            {delay: 10 * 1000}
                        } className='max-w-full !m-0'>
                    {getProjects()}
                </Swiper>

            </div>
        </div>
    );
};
